import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold', marginBottom: 12
  },
  pic: {
    height: 215,
  }
}));
