import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';

import AuthIntro, { AuthIntroProps, OccupationKeyEnum } from '../intro';
import AgreementsFooter from '@/components/agreements-footer';
import SmallLogo from '@/components/icons/small-logo';
import { useStyles } from './styles';

const Auth: React.FC<AuthIntroProps> = ({ occupationKey }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const search = history.location.search;
  const query = new URLSearchParams(search);
  const hash = query.get('hash');
  const hashUrlString = hash ? '/?hash=' + hash : '';

  const signUpHandler = React.useCallback(() => {
    history.push(`/sign-up${hashUrlString}`);
  }, [history, hashUrlString]);

  const signInHandler = React.useCallback(() => {
    history.push(`/sign-in${hashUrlString}`);
  }, [history, hashUrlString]);

  return (
    <Box component='article' px={4} pt={4} className={classes.root}>
      <Link to="/">
        <SmallLogo />
      </Link>
      <AuthIntro occupationKey={occupationKey} />
      <Box component="footer" mt={10}>
        <Box>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={signUpHandler}
            style={{ backgroundColor: "#2B75B3" }}
          >
            {t('common.sign-up')}
          </Button>
        </Box>
        <Box marginTop={4}>{
          occupationKey === OccupationKeyEnum.DOCTOR ? (
            <Typography className={classes.backupText} >
              {t('onboarding.auth-doctor.already-have-account')}{' '}
              <Link to='/' style={{ color: "inherit" }}>{t('onboarding.auth-doctor.cta-link')}</Link>
            </Typography>) : (occupationKey === OccupationKeyEnum.STUDENT ? (
              <Typography className={classes.backupText} >
                {t('onboarding.auth-student.already-have-account')}{' '}
                <Link to='/' style={{ color: "inherit" }}>{t('onboarding.auth-student.cta-link')}</Link>
              </Typography>
            ) : (
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={signInHandler}
              >
                {t('common.sign-in')}
              </Button>)
          )
        }
        </Box>
        <AgreementsFooter />
      </Box>
    </Box>
  );
};

export default Auth;
