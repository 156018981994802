import React, { useEffect, useMemo, useRef } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { isNumber } from 'lodash';

import { Skeleton } from '@material-ui/lab';
import { Box, Button, Paper } from '@material-ui/core';
import { LockOutlined, MoreVert } from '@material-ui/icons';
import { AppTab, AppTabs, TabPanel } from '@/components/tabs';

import {
  ClassRoomLearningSessionStatus,
  LekSessionStatus,
  useClassRoomSessionQuery,
  useClassRoomSettingsQuery,
  useClassRoomsFinishedLazyQuery,
  useClassRoomsOngoingLazyQuery,
  useGetActiveDuelsQuery,
  UserLekDataStatus,
} from '@/graphql';

import ClassRoomHeader from '../../header';
import ClassRoomDescription from '../../description';
import Lectures from '../../lectures';
import StackLayout from '../../../components/stack-layout';
import DropdownMenu from '../../../components/dropdown-menu';
import MembersTab from './members-tab';
import LectureProgress from '../../lecture-progress';
import ClassroomExpiredDialog from '../../classroom-expired-dialog';
import LekStart from '../../lek-start';
import { ClassroomTypes, TabNavIdsEnum } from '@/type';
import { useTabsNav } from '@/hooks/useTabsNav';
import { useCreateDuel } from '@/hooks/useCreateDuel';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { useUser } from '@/contexts/user-context';
import { useSnackbarContext } from '@/contexts/snackbar-context';
import BannerGoalReached from '../../banner-goal-reached';
import LekData from './lek-data';
import {
  CLASSROOM_MAX_DUEL_COUNT,
  FREEMIUM_MAX_DUEL_COUNT,
  LS_KEY_TABINDEX,
  MAIN_ORGANIZER_NAME,
  PREMIUM_MAX_DUEL_COUNT,
} from '@/utils/constants';
import { useStyles } from './styles';
import { useDuelLimitModal } from '@/hooks/useDuelLimitModal';

type ClassRoomParams = {
  sessionId: string;
  userSessionSettingId: string;
};
interface ClassRoomProps {
  isFinished?: boolean;
}

interface stateType {
  walkthroughStep: string;
}

const ClassRoom: React.FC<ClassRoomProps> = ({ children, isFinished }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const historyState: any = history.location.state;
  const { isUserPremium } = useGetPremiumData();
  const { user } = useUser();

  const { sessionId, userSessionSettingId } = useParams<ClassRoomParams>();
  const { state } = useLocation<stateType>();

  const { value, handleChange } = useTabsNav(
    `${TabNavIdsEnum.CLASSROOM}${sessionId}`
  );
  const elementRef = useRef<HTMLDivElement | null>(null);

  const duelLimitModal = useDuelLimitModal();

  const { setCustomSnack } = useSnackbarContext();
  const showDuelsLimitMessage = () => {
    if (isUserPremium) {
      setCustomSnack({
        visibility: true,
        message: 'classrooms.snackBars.message-cr-duels-limit',
        messageOpts: {
          value: PREMIUM_MAX_DUEL_COUNT,
        },
      });
    } else {
      duelLimitModal.open();
    }
  };

  const [getOngoingCR, { data: ongoingData, loading: ongoingLoading }] =
    useClassRoomsOngoingLazyQuery({
      fetchPolicy: 'cache-and-network',
    });
  const [getFinishedCR, { data: finishedData, loading: finishedLoading }] =
    useClassRoomsFinishedLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  const { handleCreateDuel, creatingLoading } = useCreateDuel({
    sessionId,
  });

  const { data: activeDuelsData } = useGetActiveDuelsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { data, loading } = useClassRoomSessionQuery({
    variables: { sessionId, userSessionSettingsId: userSessionSettingId },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (historyState?.showSnackCrJoined) {
        setCustomSnack({
          visibility: true,
          message: 'classrooms.snackBars.message-cr-joined',
          durationInMs: 3000,
        });
        history.replace({
          state: { ...historyState, showSnackCrJoined: null },
        });
      }
    },
  });

  const { data: settingsData, loading: settingsLoading } =
    useClassRoomSettingsQuery({
      variables: {
        learningSessionId: sessionId,
        userSessionSettingId: userSessionSettingId || '',
      },
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    if (!ongoingData && !finishedData && !ongoingLoading && !finishedLoading) {
      !!isFinished ? getFinishedCR() : getOngoingCR();
    }
  }, [
    ongoingData,
    finishedData,
    ongoingLoading,
    finishedLoading,
    getFinishedCR,
    getOngoingCR,
    isFinished,
  ]);

  useEffect(() => {
    return () => {
      localStorage.setItem(`${TabNavIdsEnum.CLASSROOM}${LS_KEY_TABINDEX}`, '0');
    };
  }, [sessionId]);

  const indicator = !!isFinished
    ? false
    : ongoingData?.ongoingClassRooms?.find(
      (it) =>
        it?.learningSession?.id === sessionId &&
        it?.learningSession.status !== ClassRoomLearningSessionStatus.Finished
    )?.isSessionActive;
  const correctAnswersAmount = !!isFinished
    ? finishedData?.finishedClassRooms?.find(
      (it) => it?.learningSession?.id === sessionId
    )?.correctAnswersAmount
    : ongoingData?.ongoingClassRooms?.find(
      (it) => it?.learningSession?.id === sessionId
    )?.correctAnswersAmount;

  const classroom = data?.classRoomLearningSession?.classRoom;
  const organizer = data?.classRoomLearningSession?.classRoom?.organizer;

  const roomClosed = useMemo(
    () => settingsData?.userClassRoomSettings?.isClosed,
    [settingsData]
  );

  const justForLek = useMemo(
    () => classroom?.enrollmentType === ClassroomTypes.LEK,
    [classroom]
  );
  const isNetworkType = useMemo(
    () => classroom?.enrollmentType === ClassroomTypes.NETWORK,
    [classroom]
  );
  const isOnboardingActive = useMemo(
    () =>
      !user?.settings?.isClassRoomIntroCompleted &&
      sessionStorage?.onBoardingIsActive &&
      state?.walkthroughStep,
    [user, state]
  );

  const isDuelsLimit =
    (classroom?.enrollmentType === ClassroomTypes.BLENDED || isUserPremium
      ? activeDuelsData?.activeDuels?.length || 0
      : activeDuelsData?.startedDuelsToday || 0) >=
    (classroom?.enrollmentType === ClassroomTypes.BLENDED
      ? CLASSROOM_MAX_DUEL_COUNT
      : isUserPremium
        ? PREMIUM_MAX_DUEL_COUNT
        : FREEMIUM_MAX_DUEL_COUNT);

  const handleClickOnStartBtn = () =>
    isDuelsLimit ? showDuelsLimitMessage() : handleCreateDuel();

  const dropMenuOptions = useMemo(() => {
    let options = [
      {
        id: '001',
        link: `/classroom/${sessionId}/${settingsData?.userClassRoomSettings?.id}/activity-details`,
        label: t('classrooms.detailed-results'),
      },
      {
        id: '002',
        link: `/classroom/${sessionId}/${settingsData?.userClassRoomSettings?.id}/leave`,
        label: t('classrooms.leave-room2'),
      },
      // {
      //   id: '003',
      //   link: 'give-feedback',
      //   label: t('classrooms.give-feedback'),
      // },
    ];
    if (
      !isNetworkType ||
      roomClosed ||
      settingsData?.userClassRoomSettings?.isAchieved
    ) {
      options = options.filter((it) => it.id !== '002');
    }

    return options;
  }, [roomClosed, sessionId, settingsData, isNetworkType, t]);

  const lekSessionInProgressOrPending = useMemo(
    () =>
      data?.lekSessionsData?.find(
        (it) =>
          it?.lekSession?.status === LekSessionStatus.InProgress ||
          it?.lekSession?.status === LekSessionStatus.Pending
      )?.lekSession,
    [data]
  );

  const lekWasPassed = useMemo(
    () =>
      data?.lekSessionsData?.find(
        (it) => it?.userLekData?.status === UserLekDataStatus.Finished
      ),
    [data]
  );

  const testsFinishedAndLekGoalWasAchieved = useMemo(
    () =>
      data?.lekSessionsData
        ?.map((it) =>
          it?.userLekData?.status === UserLekDataStatus.Finished ? it : null
        )
        ?.find((it) => it?.userLekData?.isPassed === true),
    [data]
  );

  const testsFinishedAndAllLekAttemptsWereUsed = useMemo(
    () =>
      data?.lekSessionsData?.find(
        (it) =>
          it?.lekSession?.lek?.possibleAttempts ===
          it?.userLekData?.attemptNumber
      ),
    [data]
  );

  const lekWasPassedInActiveLekSession = useMemo(
    () =>
      data?.lekSessionsData?.find(
        (it) =>
          it?.lekSession?.status === LekSessionStatus.InProgress &&
          it?.userLekData?.status === UserLekDataStatus.Finished
      ),
    [data]
  );
  const isStartLaterThanJoinDate =
    +DateTime.fromISO(data?.classRoomLearningSession?.startDate) >
    +DateTime.fromISO(settingsData?.userClassRoomSettings?.created);
  const startDate =
    isNetworkType && !isStartLaterThanJoinDate
      ? settingsData?.userClassRoomSettings?.created
      : data?.classRoomLearningSession?.startDate;
  const finishDate = isNetworkType
    ? DateTime.fromISO(
      isStartLaterThanJoinDate
        ? data?.classRoomLearningSession?.startDate
        : settingsData?.userClassRoomSettings?.created
    )
      .plus({ days: (data?.classRoomLearningSession?.duration || 1) - 1 })
      .endOf('day')
    : DateTime.fromISO(data?.classRoomLearningSession?.finishDate).endOf('day');

  const isUserJustJoined =
    +DateTime.local() -
    +DateTime.fromISO(settingsData?.userClassRoomSettings?.created) <
    6000;

  const pageTitle = useMemo(() => {
    switch (data?.classRoomLearningSession?.classRoom.enrollmentType) {
      case 1:
        return t('classrooms.single-page-title-blended');
      case 2:
        return t('classrooms.single-page-title-network');
      case 3:
        return t('classrooms.single-page-title-lek');
      default:
        return '';
    }
  }, [data, t]);

  window.onunload = function () {
    sessionStorage.removeItem('onBoardingIsActive');
  };

  return (
    <StackLayout
      title={pageTitle}
      toolbar={
        !justForLek && (
          <DropdownMenu
            btn={<MoreVert id="classroom-menu" />}
            options={dropMenuOptions}
          />
        )
      }
      back={historyState?.fromCongratulation ? '/classrooms' : undefined}
    >
      <div
        ref={elementRef}
        id="classroomScrollBox"
        style={{
          height: 'min-content',
        }}
      >
        {!justForLek &&
          !isNetworkType &&
          data?.classRoomLearningSession?.status !==
          ClassRoomLearningSessionStatus.Finished &&
          !lekWasPassedInActiveLekSession &&
          lekSessionInProgressOrPending?.finishDateTime && (
            <LekStart
              sessionId={sessionId}
              lekSessionId={lekSessionInProgressOrPending?.id}
              finishDate={lekSessionInProgressOrPending?.finishDateTime}
              startDate={lekSessionInProgressOrPending?.startDateTime}
              lekSessionStatus={lekSessionInProgressOrPending.status}
            />
          )}
        {!isFinished &&
          !!settingsData?.userClassRoomSettings?.isAchieved &&
          classroom?.enrollmentType && (
            <BannerGoalReached
              sessionSettingsId={settingsData?.userClassRoomSettings.id}
              classroomType={classroom?.enrollmentType}
            />
          )}
        <ClassRoomHeader
          userSessionSettingsId={settingsData?.userClassRoomSettings?.id}
          sessionId={sessionId}
          image={
            (organizer?.name === MAIN_ORGANIZER_NAME &&
              classroom?.backgroundImage) ||
            classroom?.classRoomImage
          }
          specialityGroupIcon={
            organizer?.name === MAIN_ORGANIZER_NAME
              ? settingsData?.userClassRoomSettings?.specialityGroupIcon
              : null
          }
          startDate={startDate}
          finishDate={finishDate.toISO()}
          title={classroom?.title}
          organizerName={organizer?.name}
          organizerId={organizer?.id}
          certificate={classroom?.certificate?.points}
          finishDateCosExpiredPremium={
            settingsData?.userClassRoomSettings?.updated
          }
          isForPremiumOnly={data?.classRoomLearningSession?.isForPremiumOnly}
          indicator={
            (isNetworkType && finishDate > DateTime.local().endOf('day')) ||
              classroom?.enrollmentType === ClassroomTypes.BLENDED
              ? indicator
              : null
          }
          classroomType={
            data?.classRoomLearningSession?.classRoom.enrollmentType
          }
          sessionStatus={data?.classRoomLearningSession?.status}
          deepLinkHash={data?.classRoomLearningSession?.deepLink}
          maxCount={data?.classRoomLearningSession?.maxUserCapacity}
        />
        {loading ? (
          <Paper>
            <Box py={5} px={6}>
              {[...Array(15)].map((i, idx) => (
                <Skeleton key={idx} height={30} />
              ))}
            </Box>
          </Paper>
        ) : (
          <>
            <AppTabs
              style={{ position: 'sticky', top: 0, zIndex: 100 }}
              value={
                isOnboardingActive ? parseInt(state.walkthroughStep) : value
              }
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <AppTab
                id="classroom-activity-tab"
                data-cy-classrooms-caption-activities
                label={t('classrooms.activities')}
              />
              {!justForLek && (
                <AppTab
                  id="classroom-attendees-tab"
                  data-cy-classrooms-caption-attendees
                  label={t('classrooms.attendees')}
                />
              )}
              <AppTab
                id="classroom-details-tab"
                data-cy-classrooms-caption-details
                label={t('classrooms.details')}
              />
            </AppTabs>
            <TabPanel
              value={
                isOnboardingActive ? parseInt(state.walkthroughStep) : value
              }
              index={0}
            >
              {!justForLek && lekWasPassed && (
                <Paper elevation={0}>
                  <Box px={4} py={2} textAlign="center">
                    <Button
                      data-cy-result-btn
                      color="primary"
                      component={Link}
                      to={`/classroom/${sessionId}/lek-results`}
                    >
                      {t('lek.result')}
                    </Button>
                  </Box>
                </Paper>
              )}
              {justForLek ? (
                <LekData isSessionFinished={isFinished} />
              ) : (
                <LectureProgress
                  sessionId={sessionId}
                  userSessionSettingId={userSessionSettingId}
                />
              )}
            </TabPanel>
            {!justForLek && (
              <TabPanel
                value={
                  isOnboardingActive ? parseInt(state.walkthroughStep) : value
                }
                index={1}
              >
                <MembersTab
                  sessionId={sessionId}
                  crTitle={data?.classRoomLearningSession?.classRoom.title}
                  roomFinished={
                    roomClosed ||
                    data?.classRoomLearningSession?.status ===
                    ClassRoomLearningSessionStatus.Finished
                  }
                  roomInProgress={
                    data?.classRoomLearningSession?.status ===
                    ClassRoomLearningSessionStatus.InProgress
                  }
                  isDuelsLimit={isDuelsLimit}
                  isNetworkType={isNetworkType}
                  deepLinkHash={data?.classRoomLearningSession?.deepLink}
                  handleClickOnPlayBtn={handleClickOnStartBtn}
                />
              </TabPanel>
            )}
            <TabPanel
              value={
                isOnboardingActive ? parseInt(state.walkthroughStep) : value
              }
              index={!justForLek ? 2 : 1}
            >
              <ClassRoomDescription
                description={classroom?.description}
                link={classroom?.learningMoreInfoLink}
                orgId={organizer?.id}
                orgName={organizer?.name}
                orgEmail={organizer?.email}
                orgLogo={organizer?.logo}
                orgWebsite={organizer?.linkToSite}
                sponsors={classroom?.sponsors}
              >
                <Lectures
                  sessionId={sessionId}
                  userSettingsId={settingsData?.userClassRoomSettings?.id}
                />
              </ClassRoomDescription>
            </TabPanel>
          </>
        )}
        {(loading ||
          settingsLoading ||
          (!justForLek &&
            finishDate >= DateTime.local().endOf('day') &&
            !roomClosed)) && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickOnStartBtn}
              id="btnAnimatedBySnack"
              startIcon={isDuelsLimit && <LockOutlined />}
              className={`${isDuelsLimit ? classes.startBtnDisabled : classes.playDuelBtn
                }`}
              disabled={
                creatingLoading ||
                data?.classRoomLearningSession?.status ===
                ClassRoomLearningSessionStatus.Pending
              }
              style={{ opacity: loading || settingsLoading ? 0 : 1 }}
            >
              {t('classrooms.play-duel')}
            </Button>
          )}
        {!loading &&
          !settingsLoading &&
          !isFinished &&
          !!settingsData?.userClassRoomSettings?.id &&
          ((data?.classRoomLearningSession &&
            roomClosed &&
            settingsData?.userClassRoomSettings?.isOut) ||
            (data?.classRoomLearningSession && !roomClosed) ||
            (isNetworkType &&
              !!data?.classRoomLearningSession?.isForPremiumOnly &&
              !isUserPremium)) &&
          (isNumber(correctAnswersAmount) || lekWasPassed) &&
          !isUserJustJoined && (
            <ClassroomExpiredDialog
              finishDate={finishDate.toISO()}
              title={data.classRoomLearningSession.classRoom.title}
              classroomType={
                data.classRoomLearningSession.classRoom.enrollmentType
              }
              sessionStatus={data.classRoomLearningSession.status}
              userCrSettingsId={settingsData?.userClassRoomSettings?.id}
              userCrSettingsIsAchieved={
                settingsData?.userClassRoomSettings?.isAchieved
              }
              userCrSettingsIsOut={settingsData?.userClassRoomSettings?.isOut}
              userCrSettingsIsClosed={
                settingsData?.userClassRoomSettings?.isClosed
              }
              classRoomLectures={
                data.classRoomLearningSession.classRoom.classRoomLecture
              }
              isPremiumEndedInPremiumCR={
                isNetworkType &&
                !!data?.classRoomLearningSession?.isForPremiumOnly &&
                !isUserPremium
              }
              testsFinishedAndLekGoalWasAchieved={
                !!testsFinishedAndLekGoalWasAchieved
              }
              testsFinishedAndAllLekAttemptsWereUsed={
                !!testsFinishedAndAllLekAttemptsWereUsed
              }
            />
          )}
      </div>
      {children}
    </StackLayout>
  );
};

export default ClassRoom;
