import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  backupText: {
    color: "#000000",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.3,
    letterSpacing: 0.4,
  }
}));
