import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DoctorSrc from '../../../images/doctor.png';
import { useStyles } from './styles';

export enum OccupationKeyEnum {
  DEFAULT = "default",
  DOCTOR = 'doctor',
  STUDENT = 'student',
};
export type AuthIntroProps = {
  occupationKey: OccupationKeyEnum;
};

const AuthIntro: React.FC<AuthIntroProps> = ({ occupationKey }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const title = t(`onboarding.auth-${occupationKey}.title`);
  const description = t(`onboarding.auth-${occupationKey}.description`);

  return (
    <Box component="section" textAlign="center" display="flex" flexDirection="column" alignItems="center" mt={2}>
      <img src={DoctorSrc} alt="Humeo" className={classes.pic} />
      <Box mt={4}>
        <Typography variant="h1" color='primary' className={classes.title}>{title}</Typography>
        <Typography variant="body1">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default AuthIntro;
